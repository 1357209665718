import { React, useState} from "react";
import ImageSpinner from "../ImageSpinner/ImageSpinner";
import "./GalleryImage.css";

const GalleryImage = (props) => {
  const [loading, setLoading] = useState(true);

  return (
    <div className="col-sm-12 col-md-6 col-lg-4 col-xl-3 px-2">
      {loading ? (
        <ImageSpinner spinnerUse={"gallery-thumbnail-spinner"} spinnerType={"gallery-spinner"}/>
      ) : null}
      <img
        src={props.src}
        style={!loading ? {} : { display: "none" }}
        className="img-fluid gallery-thumbnail my-2"
        alt="bliss nails service"
        onClick={props.handleClick}
        onLoad={() => {
          setLoading(false);
        }}
      />
    </div>
  );
};

export default GalleryImage;

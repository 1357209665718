import { React, useState } from "react";
import ImageSpinner from "../ImageSpinner/ImageSpinner";
import "./MenuImage.css"
const MenuImage = ({ src, alt, rightArrowPress, leftArrowPress, showArrows }) => {
  const [loading, setLoading] = useState(true);
  return (
    <>
      {loading ? (
        <ImageSpinner
          spinnerUse={"menu-image-spinner"}
          spinnerType={"menu-spinner"}
        />
      ) : null}
      <div>
        {showArrows && (
          <>
            <i
              className="menu-arrow arrow-right fas fa-chevron-right"
              onClick={rightArrowPress}
            ></i>
            <i className="menu-arrow arrow-left fas fa-chevron-left" onClick={leftArrowPress}></i>
          </>
        )}
        <img
          src={src}
          style={!loading ? {} : { display: "none" }}
          className="menu-image img-fluid"
          alt={alt}
          onLoad={() => {
            setLoading(false);
          }}
        />
      </div>
    </>
  );
};

export default MenuImage;

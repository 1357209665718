import "./App.css";
import Contact from "./containers/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Gallery from "./containers/Gallery/Gallery";
import Home from "./containers/Home/Home";
import Navbar from "./components/Navbar/Navbar";
import Services from "./containers/Services/Services";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <main className="page">
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/services/:service" component={Services} />
            <Route exact path="/gallery" component={Gallery} />
            <Route exact path="/contact" component={Contact} />
          </Switch>
        </main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;

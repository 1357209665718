import { React, useEffect, useState, useRef } from "react";

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'


import "./Home.css";
import { Link } from "react-router-dom";
import manicureThumb from "./assets/manicure-thumb.png";
import pedicureThumb from "./assets/pedicure-thumb.png";
import facialThumb from "./assets/facial-thumb.png";
import partiesThumb from "./assets/parties-thumb.png";

const Home = () => {
  const [aboutHeading, setAboutHeading] = useState(null);
  const [aboutHeadingLoad, setAboutHeadingLoad] = useState(false);
  const [aboutText, setAboutText] = useState(null);
  const [aboutTextLoad, setAboutTextLoad] = useState(null);
  const [aboutImgSrc, setAboutImgSrc] = useState(null);
  const [aboutImgAlt, setAboutImgAlt] = useState(null)
  const [featuredHeading, setfeaturedHeading] = useState(null);
  const [featuredHeadingLoad, setfeaturedHeadingLoad] = useState(false);
  const [featuredText, setfeaturedText] = useState(null);
  const [featuredTextLoad, setfeaturedTextLoad] = useState(null);
  const [featuredImgSrc, setfeaturedImgSrc] = useState(null);
  const [featuredImgAlt, setfeaturedImgAlt] = useState(null)

  const aboutImg = useRef(null);
  const featureImg = useRef(null);


  useEffect(() => {
    window.scrollTo(0, 0);
  },[]);

  useEffect(() => {
    getAboutMessage();
    getFeatureMessage();
  },[]);

  async function getAboutMessage() {
    const response = await fetch('https://admin.blissnas.com/wp-json/wp/v2/about');
    const aboutPosts = await response.json();

    const latestPost= aboutPosts[0]
    const title = latestPost.title.rendered;
    const text = latestPost.acf.message;
    const imageId = latestPost.featured_media;

    setAboutHeading(title);
    setAboutText(text);
    setAboutHeadingLoad(true);
    setAboutTextLoad(true);


    const imageResponse = await fetch(`https://admin.blissnas.com/wp-json/wp/v2/media/${imageId}`);
    const imageData = await imageResponse.json();

    const imageUrl = imageData.source_url;
    const imageAlt = imageData.alt_text;

    setAboutImgSrc(imageUrl);
    setAboutImgAlt(imageAlt);
  }

  async function getFeatureMessage() {
    const response = await fetch('https://admin.blissnas.com/wp-json/wp/v2/featured');
    const featuredPosts = await response.json();

    const latestPost= featuredPosts[0]
    const title = latestPost.title.rendered;
    const text = latestPost.acf.message;
    const imageId = latestPost.featured_media;

    setfeaturedHeading(title);
    setfeaturedText(text);
    setfeaturedHeadingLoad(true);
    setfeaturedTextLoad(true);


    const imageResponse = await fetch(`https://admin.blissnas.com/wp-json/wp/v2/media/${imageId}`);
    const imageData = await imageResponse.json();

    const imageUrl = imageData.source_url;
    const imageAlt = imageData.alt_text;

    setfeaturedImgSrc(imageUrl);
    setfeaturedImgAlt(imageAlt);
  }


  return (
    <>
      <div className="hero-image mb-3">
        <button className="btn hero-button">
          <a href="tel:817-849-2343">Call Us</a>
        </button>
      </div>
      <div className="container">
        <div className="row text-section p-3">
          <div className="col-sm-12 col-lg-6 mb-4">
            {aboutHeadingLoad ? <h2 className="mb-4" dangerouslySetInnerHTML={{__html: aboutHeading}}></h2>: <h2><Skeleton /></h2>}
            {aboutTextLoad ? <p dangerouslySetInnerHTML={{__html: aboutText}}></p>: <p><Skeleton count={10}/></p>}
            <img
              src={aboutImgSrc}
              className="img-fluid text-section-image"
              alt={aboutImgAlt}
              ref={aboutImg}
              onLoad={()=>{
                aboutImg.current.style.opacity = 1;
              }}
            />
          </div>
          <div className="col-sm-12 col-lg-6">
          {featuredHeadingLoad ? <h2 className="mb-4" dangerouslySetInnerHTML={{__html: featuredHeading}}></h2>: <h2><Skeleton /></h2>}
            {featuredTextLoad ? <p dangerouslySetInnerHTML={{__html: featuredText}}></p>: <p><Skeleton count={10}/></p>}
            <img
              src={featuredImgSrc}
              className="img-fluid text-section-image"
              alt={featuredImgAlt}
              ref={featureImg}
              onLoad={()=>{
                featureImg.current.style.opacity = 1;
              }}
            />
          </div>
        </div>
      </div>
      <div className="container-fluid home-services">
        <div className="container">
          <h2 className="text-center">Our Services</h2>
          <div className="row p-2">
            <div className="col-sm-12 col-md-6 col-xl-3">
              <Link to="/services/manicure" className="nav-link">
                <div className="home-service-thumb-gradient">
                  <img
                    src={manicureThumb}
                    alt="manicure services"
                    className="home-service-thumb"
                  />
                </div>
              </Link>
              <div className="row text-center">
                <h3>Manicures</h3>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-3">
              <Link to="/services/pedicure" className="nav-link">
                <div className="home-service-thumb-gradient">
                  <img
                    src={pedicureThumb}
                    alt="Pedicure services"
                    className="home-service-thumb"
                  />
                </div>
              </Link>

              <div className="row text-center">
                <h3>Pedicures</h3>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-3">
              <Link to="/services/facial" className="nav-link">
                <div className="home-service-thumb-gradient">
                  <img
                    src={facialThumb}
                    alt="facial services"
                    className="home-service-thumb"
                  />
                </div>
              </Link>

              <div className="row text-center">
                <h3>Facials</h3>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-xl-3">
              <Link to="/services/parties" className="nav-link">
                <div className="home-service-thumb-gradient">
                  <img
                    src={partiesThumb}
                    alt="group party services"
                    className="home-service-thumb"
                  />
                </div>
              </Link>
              <div className="row text-center">
                <h3>Group Parties</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;

import { React, useEffect } from "react";
import "./Contact.css";

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <div className="container my-4">
      <div className="row">
        <div className="col-sm-12 col-md-7 mb-3">
          <h3>Map:</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3350.021516213197!2d-97.23918988481489!3d32.89759938093578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864dd7bbdd8cf06b%3A0x3ae44f2f7077aa58!2sBliss%20Nails%20%26%20Spa!5e0!3m2!1sen!2sus!4v1616779170127!5m2!1sen!2sus"
            id="embedded-map"
            allowFullScreen=""
            title="google maps location"
            loading="lazy"
            className="border"
          ></iframe>
        </div>
        <div className="contact-section col-sm-12 col-md-4 ms-3">
          <div>
            <h3>Address:</h3>
            <p>
              <span>
                <i className="fas fa-map-marker-alt me-2"></i>
              </span>
              <a
                href="https://g.page/BlissNAS?share"
                target="_blank"
                rel="noreferrer"
              >
                2041 Rufe Snow Dr, Suite 315, Keller, TX 76248
              </a>
            </p>

            <h3 className="mt-4">Call Us:</h3>
            <span>
              <i className="fas fa-phone-volume me-2"></i>
              <a href="tel:817-849-2343">(817) 849-2343</a>
            </span>

            <h3 className="mt-4">Email:</h3>
            <span>
              <i className="far fa-envelope me-2"></i>
              <a href="mailto:info@blissnas.com">info@blissnas.com</a>
            </span>

            <h3 className="mt-4">Social:</h3>
            <span>
              <a
                href="https://www.facebook.com/BlissNASKeller"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook fa-3x pe-2"></i>
              </a>
              <a
                href="http://www.instagram.com"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram fa-3x px-2"></i>
              </a>
              <a
                href="https://www.yelp.com/biz/bliss-nails-and-spa-keller-2"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-yelp fa-3x px-2"></i>
              </a>
              <a
                href="https://g.page/BlissNAS?share"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-google fa-3x p-2"></i>
              </a>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;

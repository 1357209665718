import { React, useEffect, useState } from "react";
import GalleryImage from "../../components/GalleryImage/GalleryImage";
import GalleryImageModal from "../../components/GalleryImageModal/GalleryImageModal";
import "./gallery.css";

// function importAll(r) {
//   return r.keys().map(r);
// }

// const images = importAll(
//   require.context("./assets", false, /\.(png|jpe?g|svg)$/)
// );

const Gallery = () => {
  const [selectedImgSrc, setSelectedImgSrc] = useState("");
  const [images, setImages] = useState([]);

  useEffect(() => {
    // window.scrollTo(0,0);'
    getGalleryImages();
  },[]);

  async function getGalleryImages() {
    const url =
      "https://admin.blissnas.com/wp-json/filebird/public/v1/attachment-id/?folder_id=3&token=tSdNDKsILriovfBAxSSC4ufvMv7VXET930gD7xkH";
    const response = await fetch(url);
    const imagesResponse = await response.json();

    const imageIds = imagesResponse.data.attachment_ids;
    //console.log(imageIds);
    
    imageIds.map(async (id, index)=> {
      const imageUrl = await getImageUrlFromAttachmentId(id);
      console.log(imageUrl);
      setImages(oldArray => [...oldArray, imageUrl]);
    })
  }

  async function getImageUrlFromAttachmentId(id) {
    const url = `https://admin.blissnas.com/wp-json/wp/v2/media/${id}`;
    const response = await fetch(url);
    const imageResponse = await response.json();

    return imageResponse.source_url;

  }

  const handleClick = (e) => {
    setSelectedImgSrc(e.target.currentSrc);
  };

  const closeModal = (e) => {
    setSelectedImgSrc("");
  };

  return (
    <>
      <div className="gallery container mt-3">
        <div className="row">
          <h3>Gallery</h3>
        </div>
        <div className="row">
          {
            images.map((image, index) => {
              //console.log(image);
              return (
                <GalleryImage
                  handleClick={handleClick}
                  key={index}
                  src={image}
                />
              );
            })}
        </div>
      </div>
      {selectedImgSrc && (
        <GalleryImageModal src={selectedImgSrc} closeModal={closeModal} />
      )}
    </>
  );
};

export default Gallery;

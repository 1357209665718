import React from "react";
import logo from "./assets/bliss.svg";
import { Link } from "react-router-dom";
import "./Navbar.css";

const Navbar = () => {
  return (
    <nav className="navbar sticky-top flex-column">
      <Link className="mx-auto" to="/">
        <img
          src={logo}
          id="navbar-logo"
          className="img-fluid"
          alt="small bliss nail salon logo"
        />
      </Link>
      <div className="nav-links">
        <Link to="/" className="nav-link">
          Home
        </Link>
        <Link to="/services" className="nav-link">
          Services
        </Link>
        <Link to="/gallery" className="nav-link">
          Gallery
        </Link>
        <Link to="/contact" className="nav-link">
          Contact
        </Link>
      </div>
      <span className="triangle"></span>
    </nav>
  );
};

export default Navbar;

import React from "react";
import "./GalleryImageModal.css"

const GalleryImageModal = ({ src, closeModal }) => {
  return (
    <div className="image-modal" onClick={closeModal}>
      <div className="image-modal-content p-0">
        <img src={src} className="modal-img img-fluid" alt="Bliss Nails service"/>
      </div>
    </div>
  );
};

export default GalleryImageModal;

import React from "react";
import logo from "./assets/bliss-logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <footer className="footer container-fluid pt-3">
      <div className="row d-flex mx-0">
        <div className="col-sm-12 col-lg-12 col-xl-3 mb-2 d-flex justify-content-center align-items-center">
          <div className="m-0 p-0"><img src={logo} id="footer-logo" alt="Bliss Nail Salon logo full" /></div>
          
        </div>
        <div className="col-sm-12 col-md-6 col-xl-3 mb-4 d-flex justify-content-center">
          <div>
          <h5 className="footer-heading">Contact Us:</h5>
          <p className="footer-shop-name mt-3">Bliss Nails &#38; Spa</p>
          <a href="https://g.page/BlissNAS?share" target="_blank" rel="noreferrer"><p><span><i className="fas fa-map-marker-alt me-2"></i></span>2041 Rufe Snow Dr, Suite 315</p>
          <p id="address-two">Keller, TX 76248</p></a>
          <a href="tel:817-849-2343"><i className="fas fa-phone me-1" />(817) 849-2343</a>
          </div>
          
        </div>
        <div className="col-sm-12 col-md-6 col-xl-3 mb-4 d-flex justify-content-center">
          <div>
          <h5 className="footer-heading">Hours of Operation:</h5>
            <p className="mt-3">
              <span className="hours">Monday-Friday:</span> 10am-7:30pm
            </p>
            <p>
              <span className="hours">Saturday:</span> 9:30am-7pm
            </p>
            <p>
              <span className="hours">Sunday:</span> 11am-5pm
            </p>
            <p className="walk-in">Walk-ins Welcome</p>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-xl-3 mb-4  d-flex justify-content-center">
          <div>
          <h5 className="footer-heading">Social:</h5>
          <span>
            <a href="https://www.facebook.com/BlissNASKeller" target="_blank" rel="noreferrer"><i className="fab fa-facebook footer-social pe-2"></i></a>
            <a href="https://www.instagram.com/blissnaskeller/" target="_blank" rel="noreferrer"><i className="fab fa-instagram footer-social p-2"></i></a>
            <a href="https://www.yelp.com/biz/bliss-nails-and-spa-keller-2" target="_blank" rel="noreferrer"><i className="fab fa-yelp footer-social p-2"></i></a>
            <a href="https://g.page/BlissNAS?share" target="_blank" rel="noreferrer"><i className="fab fa-google footer-social p-2"></i></a>
          </span>
          </div>
        </div>
      </div>
      <div className="text-center">
        <p>Copyright &#169; 2021 Bliss Nails &#38; Spa</p>
      </div>
    </footer>
  );
};

export default Footer;

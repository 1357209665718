import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import manicureMenu from "./assets/manicure-care.png";
import pedicureMenu from "./assets/pedicure-care.png";
import facialMenu from "./assets/facial-care.png";
import groupMenu1 from "./assets/group-parties1.png";
import groupMenu2 from "./assets/group-parties2.png";
import "./Services.css";
import MenuImage from "../../components/MenuImage/MenuImage";

const Services = () => {
  const [currentPage, setCurrentPage] = useState("Manicure Care");
  const { service } = useParams();
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    switch (service) {
      case "manicure":
        setCurrentPage("Manicure Care");
        break;
      case "pedicure":
        setCurrentPage("Pedicure Care");
        break;
      case "facial":
        setCurrentPage("Facial Care");
        break;
      case "parties":
        setCurrentPage("Group Parties");
        break;
      default:
        setCurrentPage("Manicure Care");
    }
  }, [service]);

  const rightArrowPress = () => {
    switch (service) {
      case "manicure":
        history.push(`/services/pedicure`);
        break;
      case "pedicure":
        history.push(`/services/facial`);
        break;
      case "facial":
        document.querySelector(".nav").scrollTo({left:document.querySelector(".nav").scrollWidth,top:0,behavior: 'smooth'})
        history.push(`/services/parties`);
        break;
      case "parties":
        document.querySelector(".nav").scrollTo({left:0,top:0,behavior: 'smooth'})
        history.push(`/services/manicure`);
        break;
      default:
        history.push(`/services/pedicure`);
    }
  };

  const leftArrowPress = () => {
    switch (service) {
      case "manicure":
        document.querySelector(".nav").scrollTo({left:document.querySelector(".nav").scrollWidth,top:0,behavior: 'smooth'})
        history.push(`/services/parties`);
        break;
      case "pedicure":
        document.querySelector(".nav").scrollTo({left:0,top:0,behavior: 'smooth'})
        history.push(`/services/manicure`);
        
        break;
      case "facial":
        history.push(`/services/pedicure`);
        break;
      case "parties":
        
        history.push(`/services/facial`);
        break;
      default:
        document.querySelector(".nav").scrollTo({left:document.querySelector(".nav").scrollWidth,top:0,behavior: 'smooth'})
        history.push(`/services/parties`);
    }
  }

  return (
    <div className="container my-4 ">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <div
            className={
              currentPage === "Manicure Care" ? "nav-link active" : "nav-link"
            }
            onClick={() => {
              history.push(`/services/manicure`);
            }}
          >
            Manicure
          </div>
        </li>
        <li className="nav-item">
          <div
            className={
              currentPage === "Pedicure Care" ? "nav-link active" : "nav-link"
            }
            onClick={() => {
              history.push(`/services/pedicure`);
            }}
          >
            Pedicure
          </div>
        </li>
        <li className="nav-item">
          <div
            className={
              currentPage === "Facial Care" ? "nav-link active" : "nav-link"
            }
            onClick={() => {
              history.push(`/services/facial`);
            }}
          >
            Facial
          </div>
        </li>
        <li className="nav-item">
          <div
            className={
              currentPage === "Group Parties" ? "nav-link active" : "nav-link"
            }
            onClick={() => {
              history.push(`/services/parties`);
            }}
          >
            Group Parties
          </div>
        </li>
      </ul>
      <div className="menus border p-1">
        {currentPage === "Manicure Care" && (
          <MenuImage
            src={manicureMenu}
            alt={"Bliss manicure menu"}
            rightArrowPress={rightArrowPress}
            leftArrowPress={leftArrowPress}
            showArrows={true}
          />
        )}
        {currentPage === "Pedicure Care" && (
          <MenuImage
            src={pedicureMenu}
            alt={"Bliss pedicure menu"}
            rightArrowPress={rightArrowPress}
            leftArrowPress={leftArrowPress}
            showArrows={true}
          />
        )}
        {currentPage === "Facial Care" && (
          <MenuImage
            src={facialMenu}
            alt={"Bliss facial menu"}
            rightArrowPress={rightArrowPress}
            leftArrowPress={leftArrowPress}
            showArrows={true}
          />
        )}
        {currentPage === "Group Parties" && (
          <>
            <MenuImage
              src={groupMenu1}
              alt={"Bliss group parties menu page 1 of 2"}
              rightArrowPress={rightArrowPress}
              leftArrowPress={leftArrowPress}
              showArrows={true}
            />
            <MenuImage
              src={groupMenu2}
              alt={"Bliss group parties menu page 2 of 2"}
              showArrows={false}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default Services;
